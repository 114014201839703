
.section-office {
  .cls-office-1 {
    fill: none;
    stroke: #50a0ff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
  
  .cls-office-2 {
    fill: #cbcbcb;
  }
  
  .cls-office-3 {
    fill: #50a0ff;
  }
  
  .cls-office-4 {
    fill: #fff;
  }
  
  .cls-office-5 {
    fill: #b2b1b8;
  }
  
  .cls-office-6 {
    fill: #f5f5f5;
  }
  
  .cls-office-13,
  .cls-office-19,
  .cls-office-7 {
    font-size: 20px;
  }
  
  .cls-office-7 {
    fill: #393939;
    font-family: Montserrat-Regular, Montserrat;
  }
  
  .cls-office-8 {
    letter-spacing: -0.02em;
  }
  
  .cls-office-14,
  .cls-office-7,
  .cls-office-8,
  .cls-office-10,
  .cls-office-19,
  .cls-office-9 {
    font-family: Montserrat-Bold, Montserrat;
    font-weight: 700;
  }
  
  .cls-office-15,
  .cls-office-9 {
    letter-spacing: 0em;
  }
  
  .cls-office-11 {
    letter-spacing: -0.01em;
  }
  
  .cls-office-12 {
    letter-spacing: 0.01em;
  }
  
  .cls-office-7,
  .cls-office-13,
  .cls-office-19 {
    fill: #1e3c6f;
  }
  
  .cls-office-13 {
    font-family: Montserrat-Medium, Montserrat;
  }
  
  .cls-office-14 {
    letter-spacing: -0.02em;
  }
  
  .cls-office-16 {
    letter-spacing: -0.02em;
  }
  
  .cls-office-17 {
    letter-spacing: 0em;
  }
  
  .cls-office-18 {
    letter-spacing: -0.01em;
  }
  
  .cls-office-20 {
    letter-spacing: -0.01em;
  }
  
  .cls-office-21 {
    letter-spacing: -0.01em;
  }
  
  .cls-office-22 {
    letter-spacing: -0.05em;
  }
  
  .cls-office-23 {
    letter-spacing: -0.01em;
  }
  
  .cls-office-24 {
    letter-spacing: 0em;
  }
  
  .cls-office-25 {
    letter-spacing: -0.06em;
  }

  .btn-option {
    cursor: pointer;
  }

  .line-point {
    fill: transparent;
    transition: all 250ms;
    &.active {
      fill: rgb(80, 160, 255);
    }
  }

  .text-point {
    fill: rgb(30, 60, 111);
    transition: all 250ms;
    &.active {
      fill: rgb(80, 160, 255);
    }
  }

  .text-point {
    .active {
      fill: rgb(80, 160, 255);
    }
  }

  .text-point:hover {
    fill: rgb(80, 160, 255);
  }
}

#mouse-over-popover {
  .MuiPaper-root {
    border-radius: 100%;
    height: 25em;
    width: 25em;
    padding: 5.5em 2em;
    background-color: rgba(80, 160, 255, 0.95);
    box-shadow: none;
    text-align: center;
    .container-text-popup {
      color: white;
    }
  }

  .circle-0 {
    padding-top: 2em;
  }

  .circle-2 {
    padding-top: 50px;
  }

  .circle-3 {
    padding-top: 2em;
  }

  .circle-5 {
    padding-top: 2em;
  }

  .circle-6 {
    padding-top: 2em;
  }
}
@import url(https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: 'Montserrat', sans-serif;
  -moz-osx-font-smoothing: 'Montserrat', sans-serif; }

.w-100 {
  width: 100%; }

.text-white {
  color: white; }

@media (min-width: 0px) {
  html, body {
    font-size: 9px; } }

@media (min-width: 600px) {
  html, body {
    font-size: 13px; } }

@media (min-width: 960px) {
  html, body {
    font-size: 16px; } }

@media (min-width: 1280px) {
  html, body {
    font-size: 16px; } }

@media (min-width: 1920px) {
  html, body {
    font-size: 20px; } }

span {
  font-size: inherit; }

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit; }

.color-text-dark {
  color: #404854 !important; }

.color-text-dark-semi {
  color: #03619c !important; }

.color-text-dark-important {
  color: #1e3c6f !important; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

.light {
  font-weight: 200; }

.regular {
  font-weight: 400; }

.semi-bold-regular {
  font-weight: 500; }

.semi-bold {
  font-weight: 600; }

.bold {
  font-weight: 700; }

.font-italic {
  font-style: italic; }

.uppercase {
  text-transform: uppercase; }

.italic {
  font-style: italic; }

.w-100 {
  width: 100%; }

.container-navbar .btn-icon-menu {
  z-index: 1400;
  background-color: white;
  position: fixed;
  right: 6em;
  top: 2em;
  width: 2em;
  height: 2em;
  min-height: auto;
  min-width: auto;
  padding: .7em;
  box-sizing: content-box;
  -webkit-transition: right 200ms;
  transition: right 200ms;
  box-shadow: 0px 3px 5px -1px rgba(0, 74, 119, 0.2), 0px 6px 10px 0px rgba(0, 74, 119, 0.14), 0px 1px 18px 0px rgba(0, 74, 119, 0.12); }
  .container-navbar .btn-icon-menu .icon-show-menu {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms; }
  .container-navbar .btn-icon-menu.close {
    right: 16em; }
    .container-navbar .btn-icon-menu.close .icon-show-menu {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg); }

@media (max-width: 600px) {
  .container-navbar .btn-icon-menu {
    top: 3.3em;
    right: 3em;
    width: 3em;
    height: 3em; } }

.icon-show-menu {
  width: 100%; }

.container-close-menu {
  margin: 5.5em 0; }
  .container-close-menu .btn-icon-menu-close {
    z-index: 99;
    background-color: white;
    right: -30px;
    top: 5%;
    width: 2em;
    height: 2em;
    min-height: auto;
    min-width: auto;
    padding: .7em;
    box-sizing: content-box; }
    .container-close-menu .btn-icon-menu-close .icon-show-menu {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg); }
  .container-close-menu .MuiFab-root {
    box-shadow: none !important; }

@media (max-width: 600px) {
  .container-close-menu {
    margin: 7.5em 0; } }

.navBar-right .MuiPaper-root {
  width: 19em; }

.navBar-right .MuiListItem-button:hover {
  background-color: #1e3c6f !important; }

.navBar-right .MuiBackdrop-root {
  background-color: #004a7780 !important; }

.navBar-right .MuiPaper-root {
  color: white !important;
  background-color: #03619c !important; }

.navBar-right .split-section {
  margin: 1em;
  font-style: italic;
  font-weight: 100; }

.navBar-right .container-menu-secundary a div {
  padding-left: 21px; }

.container-links-navbar a {
  font-size: inherit;
  font-style: inherit;
  color: inherit;
  text-decoration: none; }

.list-secondary-pages span {
  font-size: .8em; }

.section-header .section-background {
  height: 80vh;
  min-height: 780px;
  width: 100vw;
  background-position: 0% 0%;
  background-size: cover;
  display: flex;
  box-shadow: inset 0px -110px 68px -23px white; }
  .section-header .section-background .section-text-left {
    font-weight: bold;
    color: white;
    font-size: 3.3em;
    line-height: 1.2;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .section-header .section-background .text-header-main {
    font-size: 1.3em;
    font-weight: 300;
    line-height: 1.2; }
  .section-header .section-background .container-text {
    margin-top: 6em; }

@media (max-width: 1280px) and (orientation: portrait) {
  .section-header .section-background {
    align-items: normal;
    height: 50vh !important;
    min-height: 740px;
    background-position: 30% 0%; }
    .section-header .section-background .section-text-left {
      margin-top: 1em; } }

@media (max-width: 1280px) and (orientation: landscape) {
  .section-header .section-background {
    align-items: normal;
    height: 50vh !important;
    background-position: 20% 0%;
    min-height: 630px; }
    .section-header .section-background .section-text-left {
      margin-top: 1em; } }

@media (max-width: 600px) and (orientation: portrait) {
  .section-header .section-background {
    align-items: normal;
    height: 60vh !important;
    background-position: 50% 0%;
    min-height: 550px; }
    .section-header .section-background .section-text-left {
      margin-top: 1em; }
    .section-header .section-background .text-header-main {
      font-size: 1.5em; } }

@media (max-width: 600px) and (orientation: landscape) {
  .section-header .section-background {
    align-items: normal;
    height: 60vh !important;
    background-position: 50% 0%; }
    .section-header .section-background .section-text-left {
      margin-top: 1em; }
    .section-header .section-background .text-header-main {
      font-size: 1.5em; } }

.header-logo {
  margin-top: 3.1em; }
  .header-logo .logo {
    height: 4.8em; }

.section-statistics {
  padding: 2em 0 8em 0; }
  .section-statistics .title-entorno-empresarial {
    font-size: 2.1em;
    border-left: solid 0.2em rgba(0, 0, 0, 0);
    padding-left: 3rem;
    color: #03619c;
    font-weight: 600; }
  .section-statistics .main-text {
    font-size: 1.3em;
    font-weight: 300; }
  .section-statistics .img-futuro {
    width: 7.5em; }
  .section-statistics .container-statistics {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center; }
  .section-statistics .container-text-progress {
    font-size: .8em;
    font-weight: 500; }
  .section-statistics .color-yellow {
    color: #ff8b00; }
  .section-statistics .color-orange {
    color: #ff4800; }
  .section-statistics .container-text-empresas {
    max-width: 770px; }
  .section-statistics .line-right {
    border-right: solid 0.4em #2884b0; }
  @media (max-width: 600px) and (orientation: portrait) {
    .section-statistics .container-statistics {
      grid-template-columns: 100%; } }
  @media (max-width: 600px) and (orientation: landscape) {
    .section-statistics .container-statistics {
      grid-template-columns: 100%; } }

.progressCircle {
  display: inline-block; }

@-webkit-keyframes load {
  0% {
    stroke-dashoffset: 0; } }

@keyframes load {
  0% {
    stroke-dashoffset: 0; } }
  .progressCircle .progress {
    position: relative;
    display: inline-block;
    padding: 0;
    text-align: center; }
  .progressCircle .porcent-text {
    font-size: 2.5em;
    font-weight: 700; }
  .progressCircle .progress .bar {
    display: inline-block;
    position: relative;
    text-align: center;
    color: #93a2ac;
    font-family: Lato;
    font-weight: 100;
    margin: 2rem; }
    .progressCircle .progress .bar .container-info {
      font-family: 'Montserrat', sans-serif;
      -webkit-font-smoothing: 'Montserrat', sans-serif;
      -moz-osx-font-smoothing: 'Montserrat', sans-serif;
      position: absolute;
      top: 3em;
      text-align: center;
      width: 100%; }
  .progressCircle .progress svg {
    width: 14em;
    height: 14em; }
  .progressCircle .progress svg:nth-child(2) {
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  .progressCircle .progress svg:nth-child(2) path {
    fill: none;
    stroke-width: 25;
    stroke-dasharray: 629;
    stroke: #fff;
    opacity: 0.9; }
  .progressCircle.actived .progress svg:nth-child(2) path {
    -webkit-animation: load 2s;
            animation: load 2s; }

.section-between {
  background-attachment: fixed;
  background-position: 20% 22%;
  background-repeat: no-repeat;
  background-size: 130%;
  padding-top: 10em;
  margin-bottom: -13em;
  padding-bottom: 20em; }

.section-help {
  color: white; }
  .section-help .title {
    font-size: 2em;
    margin-bottom: 3em;
    margin-bottom: 1.4em; }
  .section-help .text {
    font-size: 1.6em; }

@media (max-width: 1280px) and (orientation: portrait) {
  .section-between {
    padding-top: 12em;
    margin-bottom: -6em;
    padding-bottom: 12em;
    background-size: cover; } }

@media (max-width: 1280px) and (orientation: landscape) {
  .ssection-between {
    padding-top: 12em;
    margin-bottom: -6em;
    padding-bottom: 12em;
    background-size: cover; } }

@media (max-width: 960px) and (orientation: portrait) {
  .section-between {
    padding-top: 5em;
    margin-bottom: -2em;
    padding-bottom: 5em;
    background-size: cover; } }

@media (max-width: 960px) and (orientation: landscape) {
  .ssection-between {
    padding-top: 5em;
    margin-bottom: -2em;
    padding-bottom: 5em;
    background-size: cover; } }

@media (max-width: 600px) and (orientation: portrait) {
  .section-between {
    padding-top: 5em;
    margin-bottom: -2em;
    padding-bottom: 5em;
    background-size: cover; } }

@media (max-width: 600px) and (orientation: landscape) {
  .ssection-between {
    padding-top: 5em;
    margin-bottom: -2em;
    padding-bottom: 5em;
    background-size: cover; } }

footer {
  margin-top: 1px; }
  footer .flex-space-between {
    display: flex;
    justify-content: space-between;
    border-top: solid 1px #949494; }
    footer .flex-space-between a {
      color: inherit;
      font-size: inherit;
      text-decoration: inherit; }
  footer .container-footer-info {
    padding: 1.5em 1em; }

.container-contact .MuiButtonBase-root {
  color: #00aad1;
  border: 1px solid rgba(0, 170, 209, 0.23); }

.container-contact .container-form {
  background-color: white;
  max-width: 1048px;
  width: calc(100% - 4em);
  margin: auto;
  border-radius: 20px 20px 0 0;
  padding: 2em;
  box-shadow: -14px -14px 19px -9px rgba(0, 74, 119, 0.2), 14px -14px 19px -9px rgba(0, 74, 119, 0.3); }

.container-contact .container-contact-info {
  display: grid;
  grid-template-columns: calc(50% - 3em) 50%;
  grid-column-gap: 3em; }

.container-contact .MuiInput-underline:after {
  border-bottom: 2px solid #00aad1; }

.container-contact .MuiFormLabel-root.Mui-focused {
  color: #00aad1; }

.container-contact .section-btn-map {
  display: grid;
  grid-template-columns: 30% 70%; }

.container-contact .btn-map {
  cursor: pointer; }
  .container-contact .btn-map img {
    width: 4.5em;
    max-width: 80%; }

.container-contact .text-address {
  font-weight: 300;
  color: #00aad1; }

.container-contact .map {
  position: relative !important;
  width: 100% !important;
  height: 600px !important; }

.container-contact .myMark {
  height: 10px;
  width: 10px;
  background-color: red; }

.container-contact .title-contact {
  color: #878a8e;
  font-size: 1.5em; }

.container-contact .send-message {
  color: #03619c;
  font-size: 1.5em; }

.container-contact .orCall {
  font-weight: 200;
  color: #404854; }

.container-contact .phone {
  color: #ff5b00;
  text-decoration: none;
  font-size: 1.8em; }

.container-contact .contact-text-info {
  font-size: 1.3em;
  line-height: 1.2; }

.container-contact .link {
  color: #00aad1;
  text-decoration: underline;
  font-size: inherit; }

.container-contact .title-follow {
  color: #03619c;
  font-size: 1.4em; }

.container-contact .content-socialnetwork .icon-socialNetwork {
  margin-top: 1em; }
  .container-contact .content-socialnetwork .icon-socialNetwork img {
    width: 1.8em;
    margin-right: .7em; }

.container-contact .flex-space-between {
  display: flex;
  justify-content: space-between;
  border-top: solid 1px #949494; }
  .container-contact .flex-space-between a {
    color: inherit;
    font-size: inherit;
    text-decoration: inherit; }

.MuiSnackbar-root .MuiTypography-root {
  background-color: #1e3c6f;
  color: white; }
  .MuiSnackbar-root .MuiTypography-root #message-id {
    display: flex;
    align-items: center; }

.section-whatDoWeDo {
  background-color: #EDF7FF;
  padding: 10em 0; }
  .section-whatDoWeDo .MuiExpansionPanelDetails-root {
    padding-left: 0; }
  .section-whatDoWeDo .title-section-whatDoWeDo .title-section {
    font-size: 2.1em;
    border-left: solid 0.2em transparent;
    padding-left: 3rem; }
  .section-whatDoWeDo .container-more-information {
    width: 100%;
    text-align: right; }
    .section-whatDoWeDo .container-more-information a {
      color: #139CE1;
      text-decoration: none; }
  .section-whatDoWeDo .container-info-what-do-we-do {
    font-weight: 400;
    border-left: solid 0.4em #2884b0;
    padding-left: 3rem;
    margin: 1em 0;
    padding: 1em 0em 1em 3em; }
  .section-whatDoWeDo .container-expands {
    max-width: 800px;
    margin: auto; }
    .section-whatDoWeDo .container-expands .MuiPaper-root {
      border-radius: 10px 10px 0 0;
      box-shadow: 0 4px 5px 0 rgba(178, 222, 249, 0.14), 0 1px 10px 0 rgba(178, 222, 249, 0.12), 0 2px 4px -1px rgba(178, 222, 249, 0.3); }
      .section-whatDoWeDo .container-expands .MuiPaper-root .MuiExpansionPanelSummary-root {
        padding-left: 0; }
      .section-whatDoWeDo .container-expands .MuiPaper-root .MuiExpansionPanelSummary-content {
        margin: 0; }
    .section-whatDoWeDo .container-expands .content-expand {
      margin-bottom: 1em; }
    .section-whatDoWeDo .container-expands .title-expand {
      width: 100%;
      margin: 1em;
      display: grid;
      align-items: center;
      justify-items: left;
      grid-template-columns: 20% 80%; }
      .section-whatDoWeDo .container-expands .title-expand .container-img {
        width: 5em;
        width: 100%; }
        .section-whatDoWeDo .container-expands .title-expand .container-img img {
          display: block;
          margin: 0 auto;
          height: 3.5em; }
      .section-whatDoWeDo .container-expands .title-expand .container-title-expand {
        padding: 1.3em 0px; }
        .section-whatDoWeDo .container-expands .title-expand .container-title-expand .title {
          color: #1e3c6f;
          font-weight: 500; }
        .section-whatDoWeDo .container-expands .title-expand .container-title-expand .info {
          margin-top: .5em;
          font-size: .9em;
          color: #6b6b6b;
          font-weight: 400; }
  .section-whatDoWeDo .container-section-info-expand {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 40% 60%; }
    .section-whatDoWeDo .container-section-info-expand .container-icon {
      width: 70%; }
      .section-whatDoWeDo .container-section-info-expand .container-icon .icon-logo {
        width: 100%; }
    .section-whatDoWeDo .container-section-info-expand .container-info {
      margin-top: .5em;
      font-size: .9em;
      color: #6b6b6b;
      font-weight: 300; }
      .section-whatDoWeDo .container-section-info-expand .container-info ol {
        padding-left: 20px; }
        .section-whatDoWeDo .container-section-info-expand .container-info ol li {
          margin-top: 1em; }
  .section-whatDoWeDo .subtitle-section {
    font-size: 1.3em; }

.section-accompaniment {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 10em;
  padding-bottom: 10em;
  color: white; }
  .section-accompaniment .img-people {
    width: 6em; }
  .section-accompaniment .title {
    font-size: 2em;
    margin-bottom: 3em;
    margin-bottom: 1.4em; }
  .section-accompaniment .text {
    font-size: 1em; }

.section-header-stories {
  position: relative; }
  .section-header-stories .section-background {
    height: 90vh;
    min-height: 930px;
    width: 100vw;
    background-position: 0% 100%;
    background-size: cover;
    display: flex;
    box-shadow: none;
    margin-bottom: -11em; }
    .section-header-stories .section-background .container-text {
      margin: 0;
      position: absolute;
      top: 175px;
      width: 100%;
      text-align: center; }
    .section-header-stories .section-background .section-text-center {
      font-weight: bold;
      text-transform: uppercase;
      color: #03619c;
      font-size: 5.2em;
      line-height: 1.2;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
      .section-header-stories .section-background .section-text-center h1 {
        margin-bottom: 2rem; }
      .section-header-stories .section-background .section-text-center h2 {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 500;
        color: #03619c; }

@media (max-width: 1280px) and (orientation: portrait) {
  .section-background {
    min-height: 720px !important; }
    .section-background .section-text-center {
      font-size: 3.8em !important; }
    .section-background .container-text {
      top: 175px; } }

@media (max-width: 1280px) and (orientation: landscape) {
  .section-background {
    min-height: 720px !important; }
    .section-background .section-text-center {
      font-size: 3.8em !important; }
    .section-background .container-text {
      top: 175px; } }

@media (max-width: 600px) and (orientation: portrait) {
  .section-background {
    min-height: 400px !important; }
    .section-background .section-text-center {
      font-size: 3em !important; }
    .section-background .container-text {
      top: 125px !important; } }

@media (max-width: 600px) and (orientation: landscape) {
  .section-background {
    min-height: 400px !important; }
    .section-background .section-text-center {
      font-size: 3em !important; }
    .section-background .container-text {
      top: 125px !important; } }

.posts {
  margin: 3em auto 9em auto;
  max-width: 1200px; }
  .posts .title {
    color: #1e3c6f;
    font-size: 3.5em;
    line-height: 1.2;
    font-weight: 200;
    text-transform: uppercase; }
  .posts .createdBy {
    color: #a4a4a5;
    font-size: 1.4em;
    font-weight: 300; }
  .posts .summary {
    font-weight: 300;
    color: #404854;
    margin-bottom: 3em; }
    .posts .summary p {
      font-size: 1.1em; }
  .posts .btn-full-note {
    background-color: #42d1ab;
    color: white;
    padding: .7em 2.2em;
    font-weight: 300;
    text-transform: uppercase; }
  .posts .btn-full-note:hover {
    background-color: #21aa83; }
  .posts a {
    text-decoration: none; }

.section-back-posts {
  background-color: transparent;
  position: relative;
  width: 100%;
  padding: 0 4em;
  margin-bottom: 5em; }

.card-posts {
  background-color: white;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  border-radius: 15px 15px 0 0;
  box-shadow: 0px 0px 20px 6px rgba(178, 222, 249, 0.5);
  padding: 1em; }
  .card-posts .title-historias {
    color: #404854;
    text-transform: uppercase;
    font-size: 1.2em; }
  .card-posts .split-line {
    width: 100%;
    max-width: 170px;
    background-color: #b5b7ba;
    height: 1px;
    margin: auto;
    margin-top: 3em;
    margin-bottom: 5em; }

@media (max-width: 1280px) and (orientation: portrait) {
  .section-back-posts {
    padding: 0 5em !important; } }

@media (max-width: 1280px) and (orientation: landscape) {
  .section-back-posts {
    padding: 0 5em !important; } }

@media (max-width: 600px) and (orientation: portrait) {
  .section-back-posts {
    padding: 0 1em !important; } }

@media (max-width: 600px) and (orientation: landscape) {
  .section-back-posts {
    padding: 0 1em !important; } }

.all-note .btn-all-note {
  background-color: #1e3c6f;
  color: white;
  padding: .7em 2.2em;
  font-weight: 300;
  text-transform: uppercase; }

.all-note .btn-all-note:hover {
  background-color: #2162c1; }


.section-header-post {
  position: relative; }
  .section-header-post .section-background {
    height: 90vh;
    min-height: 930px;
    width: 100vw;
    background-position: 0% 100%;
    background-size: cover;
    display: flex;
    box-shadow: none;
    margin-bottom: -11em; }
    .section-header-post .section-background .container-text {
      margin: 0;
      position: absolute;
      top: 230px;
      width: 100%;
      text-align: center; }
    .section-header-post .section-background .section-text-center {
      font-weight: bold;
      text-transform: uppercase;
      color: #03619c;
      font-size: 5.2em;
      line-height: 1.2;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
      .section-header-post .section-background .section-text-center h1 {
        margin-bottom: 2rem; }
      .section-header-post .section-background .section-text-center h2 {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 500;
        color: #03619c; }

.section-post {
  background-color: rgba(0, 0, 0, 0);
  position: relative;
  width: 100%;
  padding: 0 4em;
  margin-bottom: 5em; }
  @media (max-width: 1280px) and (orientation: portrait) {
    .section-post {
      padding: 0 5em !important; } }
  @media (max-width: 1280px) and (orientation: landscape) {
    .section-post {
      padding: 0 5em !important; } }
  @media (max-width: 600px) and (orientation: portrait) {
    .section-post {
      padding: 0 1em !important; } }
  @media (max-width: 600px) and (orientation: landscape) {
    .section-post {
      padding: 0 1em !important; } }
  .section-post .posts {
    margin: 3em auto 9em auto;
    max-width: 1200px; }
    .section-post .posts .title {
      color: #1e3c6f;
      font-size: 3.5em;
      line-height: 1.2;
      font-weight: 200;
      text-transform: uppercase; }
    .section-post .posts .createdBy {
      color: #a4a4a5;
      font-size: 1.4em;
      font-weight: 300; }
    .section-post .posts .text {
      font-weight: 300;
      color: #404854; }
      .section-post .posts .text p {
        font-size: 1.1em; }
    .section-post .posts .btn-full-note {
      background-color: #42d1ab;
      color: white;
      padding: .7em 2.2em;
      font-weight: 300;
      text-transform: uppercase; }
    .section-post .posts .btn-full-note:hover {
      background-color: #21aa83; }
    .section-post .posts a {
      text-decoration: none; }
    .section-post .posts .container-img {
      text-align: center; }
    .section-post .posts img {
      max-width: 100%; }
    .section-post .posts h1 {
      font-size: 2em;
      font-weight: 600; }
    .section-post .posts h2 {
      font-size: 1.7em;
      font-weight: 500; }
    .section-post .posts h3 {
      font-size: 1.4em;
      font-weight: 500; }
    .section-post .posts h4 {
      font-size: 1.2em;
      font-weight: 400; }
    .section-post .posts .content-socialnetwork {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      .section-post .posts .content-socialnetwork .title-follow {
        color: #03619c;
        font-size: 1.2em;
        margin-right: .5em; }
      .section-post .posts .content-socialnetwork .icon-socialNetwork {
        display: flex;
        justify-content: flex-end;
        align-items: center; }
      .section-post .posts .content-socialnetwork .icon-socialNetwork img {
        width: 1.8em;
        margin-right: .5em; }
      .section-post .posts .content-socialnetwork a {
        height: 36px; }


.section-header-typicalMorning .section-background {
  height: 80vh;
  min-height: 780px;
  width: 100vw;
  background-position: 0% 0%;
  background-size: cover;
  display: flex; }
  .section-header-typicalMorning .section-background .section-text-left {
    font-weight: bold;
    color: #1e3c6f;
    font-size: 4.7em;
    line-height: 1.2;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .section-header-typicalMorning .section-background .container-text {
    margin-top: 9em; }

.section-header-typicalMorning .text-normal {
  font-size: 0.6em;
  font-weight: 100;
  position: relative;
  top: -.7em;
  left: -.5em; }

@media (max-width: 1280px) and (orientation: portrait) {
  .section-header-typicalMorning .section-background {
    align-items: normal;
    height: 50vh !important;
    min-height: 740px;
    background-position: 30% 0%; }
    .section-header-typicalMorning .section-background .section-text-left {
      margin-top: 1em; } }

@media (max-width: 1280px) and (orientation: landscape) {
  .section-header-typicalMorning .section-background {
    align-items: normal;
    height: 50vh !important;
    background-position: 20% 0%;
    min-height: 630px; }
    .section-header-typicalMorning .section-background .section-text-left {
      margin-top: 1em; } }

@media (max-width: 600px) and (orientation: portrait) {
  .section-header-typicalMorning .section-text-left {
    font-size: 3.7em !important; }
  .section-header-typicalMorning .section-background {
    align-items: normal;
    height: 60vh !important;
    background-position: 50% 0%;
    min-height: 550px; }
    .section-header-typicalMorning .section-background .section-text-left {
      margin-top: 5em; } }

@media (max-width: 600px) and (orientation: landscape) {
  .section-header-typicalMorning .section-text-left {
    font-size: 3.7em !important; }
  .section-header-typicalMorning .section-background {
    align-items: normal;
    height: 60vh !important;
    background-position: 50% 0%; }
    .section-header-typicalMorning .section-background .section-text-left {
      margin-top: 5em; } }

.section-WhatTypicalMorning {
  font-size: 16px !important; }
  @media (max-width: 600px) {
    .section-WhatTypicalMorning {
      font-size: 14px !important; } }
  .section-WhatTypicalMorning .title {
    padding: 3.5em 1em;
    background-color: #3c7dd8; }
    .section-WhatTypicalMorning .title .text {
      color: white;
      font-size: 1.9rem; }
  .section-WhatTypicalMorning .container-info {
    font-size: 1.22em;
    padding: 4.5rem 0; }
  .section-WhatTypicalMorning .image-general-info {
    padding: 0 2em; }
  .section-WhatTypicalMorning .general-info {
    padding: 4.5rem 0 0 0;
    display: grid;
    align-items: center;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr)); }
  .section-WhatTypicalMorning .container-line {
    position: relative;
    padding: 0 0 0 2em; }
    .section-WhatTypicalMorning .container-line .line-left {
      position: absolute;
      height: 9em;
      width: .4em;
      background-color: #03619c;
      left: 0; }

.section-AdministrativeAssistant {
  font-size: 16px !important;
  background-color: #3c7dd8;
  padding-top: 2em;
  padding-bottom: 6em; }
  @media (max-width: 600px) {
    .section-AdministrativeAssistant {
      font-size: 14px !important; } }
  .section-AdministrativeAssistant .title {
    padding: 3.5em 1em;
    background-color: #3c7dd8; }
    .section-AdministrativeAssistant .title .text {
      color: white;
      font-size: 2rem;
      font-weight: 500; }
  .section-AdministrativeAssistant .container-administrativeAssistant {
    margin-bottom: 3em; }
    .section-AdministrativeAssistant .container-administrativeAssistant img {
      max-width: 200px;
      width: 100%; }
  .section-AdministrativeAssistant .info-text {
    font-size: 1.22em; }

.section-Persons {
  font-size: 16px !important; }
  @media (max-width: 600px) {
    .section-Persons {
      font-size: 14px !important; } }
  .section-Persons .background-section {
    background-position: 50% 50%;
    background-size: cover; }
    .section-Persons .background-section .general-info {
      padding: 4.5rem 0 4.5rem 0;
      font-size: 1.22em;
      display: grid;
      grid-column-gap: 2em;
      grid-row-gap: 2em;
      grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));
      align-items: center; }
  .section-Persons .img-persons {
    max-width: 420px; }

.section-office .cls-office-1 {
  fill: none;
  stroke: #50a0ff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px; }

.section-office .cls-office-2 {
  fill: #cbcbcb; }

.section-office .cls-office-3 {
  fill: #50a0ff; }

.section-office .cls-office-4 {
  fill: #fff; }

.section-office .cls-office-5 {
  fill: #b2b1b8; }

.section-office .cls-office-6 {
  fill: #f5f5f5; }

.section-office .cls-office-13,
.section-office .cls-office-19,
.section-office .cls-office-7 {
  font-size: 20px; }

.section-office .cls-office-7 {
  fill: #393939;
  font-family: Montserrat-Regular, Montserrat; }

.section-office .cls-office-8 {
  letter-spacing: -0.02em; }

.section-office .cls-office-14,
.section-office .cls-office-7,
.section-office .cls-office-8,
.section-office .cls-office-10,
.section-office .cls-office-19,
.section-office .cls-office-9 {
  font-family: Montserrat-Bold, Montserrat;
  font-weight: 700; }

.section-office .cls-office-15,
.section-office .cls-office-9 {
  letter-spacing: 0em; }

.section-office .cls-office-11 {
  letter-spacing: -0.01em; }

.section-office .cls-office-12 {
  letter-spacing: 0.01em; }

.section-office .cls-office-7,
.section-office .cls-office-13,
.section-office .cls-office-19 {
  fill: #1e3c6f; }

.section-office .cls-office-13 {
  font-family: Montserrat-Medium, Montserrat; }

.section-office .cls-office-14 {
  letter-spacing: -0.02em; }

.section-office .cls-office-16 {
  letter-spacing: -0.02em; }

.section-office .cls-office-17 {
  letter-spacing: 0em; }

.section-office .cls-office-18 {
  letter-spacing: -0.01em; }

.section-office .cls-office-20 {
  letter-spacing: -0.01em; }

.section-office .cls-office-21 {
  letter-spacing: -0.01em; }

.section-office .cls-office-22 {
  letter-spacing: -0.05em; }

.section-office .cls-office-23 {
  letter-spacing: -0.01em; }

.section-office .cls-office-24 {
  letter-spacing: 0em; }

.section-office .cls-office-25 {
  letter-spacing: -0.06em; }

.section-office .btn-option {
  cursor: pointer; }

.section-office .line-point {
  fill: transparent;
  -webkit-transition: all 250ms;
  transition: all 250ms; }
  .section-office .line-point.active {
    fill: #50a0ff; }

.section-office .text-point {
  fill: #1e3c6f;
  -webkit-transition: all 250ms;
  transition: all 250ms; }
  .section-office .text-point.active {
    fill: #50a0ff; }

.section-office .text-point .active {
  fill: #50a0ff; }

.section-office .text-point:hover {
  fill: #50a0ff; }

#mouse-over-popover .MuiPaper-root {
  border-radius: 100%;
  height: 25em;
  width: 25em;
  padding: 5.5em 2em;
  background-color: rgba(80, 160, 255, 0.95);
  box-shadow: none;
  text-align: center; }
  #mouse-over-popover .MuiPaper-root .container-text-popup {
    color: white; }

#mouse-over-popover .circle-0 {
  padding-top: 2em; }

#mouse-over-popover .circle-2 {
  padding-top: 50px; }

#mouse-over-popover .circle-3 {
  padding-top: 2em; }

#mouse-over-popover .circle-5 {
  padding-top: 2em; }

#mouse-over-popover .circle-6 {
  padding-top: 2em; }

.section-Points {
  font-size: 16px !important; }
  @media (max-width: 600px) {
    .section-Points {
      font-size: 14px !important; } }
  .section-Points .container-expands {
    max-width: 800px;
    margin: auto; }
    .section-Points .container-expands .MuiPaper-root {
      border-radius: 10px 10px 0 0;
      box-shadow: 0 4px 5px 0 rgba(178, 222, 249, 0.14), 0 1px 10px 0 rgba(178, 222, 249, 0.12), 0 2px 4px -1px rgba(178, 222, 249, 0.3); }
      .section-Points .container-expands .MuiPaper-root .MuiExpansionPanelSummary-root {
        padding-left: 0; }
      .section-Points .container-expands .MuiPaper-root .MuiExpansionPanelSummary-content {
        margin: 0; }
    .section-Points .container-expands .content-expand {
      margin-bottom: 1em; }
    .section-Points .container-expands .title-expand {
      width: 100%;
      margin: 1em; }
      .section-Points .container-expands .title-expand .container-img {
        width: 5em;
        width: 100%; }
        .section-Points .container-expands .title-expand .container-img img {
          display: block;
          margin: 0 auto;
          height: 3.5em; }
      .section-Points .container-expands .title-expand .container-title-expand {
        padding: 1.3em 0px; }
        .section-Points .container-expands .title-expand .container-title-expand .title {
          color: #1e3c6f;
          font-weight: 500; }
          .section-Points .container-expands .title-expand .container-title-expand .title span {
            -webkit-transition: color 250ms;
            transition: color 250ms; }
            .section-Points .container-expands .title-expand .container-title-expand .title span.active {
              color: #59A6FF; }
        .section-Points .container-expands .title-expand .container-title-expand .info {
          margin-top: .5em;
          font-size: .9em;
          color: #6b6b6b;
          font-weight: 400; }
  .section-Points .container-office-svg {
    margin-bottom: 4em; }

.save-images .MuiDialog-container .MuiPaper-rounded {
  width: 100%;
  max-width: 100% !important; }

.image-uploaded {
  display: inline-block;
  width: calc(20% - 10px);
  margin-bottom: 2em;
  margin-right: 10px; }
  .image-uploaded .title {
    text-align: center;
    margin-bottom: 5px; }
  .image-uploaded img {
    max-width: 100%;
    max-height: 100px;
    margin: auto;
    display: block; }
  .image-uploaded .btn-images {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
    text-align: center; }
  .image-uploaded .btn-images-note {
    background-color: #03619c;
    color: white;
    border-radius: 5px;
    -webkit-transition: all 250ms;
    transition: all 250ms; }
    .image-uploaded .btn-images-note > div {
      padding: 5px; }
    .image-uploaded .btn-images-note:hover {
      background-color: #1eb0f1; }

.container-copy-image {
  cursor: pointer; }

.MuiSnackbarContent-message {
  width: 100%; }
  .MuiSnackbarContent-message #message-id {
    display: inline-block !important;
    width: 100%;
    text-align: center !important; }

.circularProgress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(212, 212, 212, 0.35);
  z-index: 9999;
  display: none; }
  .circularProgress.activated {
    display: block; }
  .circularProgress .MuiCircularProgress-root {
    top: 50%;
    position: absolute;
    left: 50%; }

.formToChangeData .container-form form {
  padding: 2em; }

.formToChangeData .containerInput {
  margin-bottom: 2em; }

.formToChangeData .inputs {
  width: 100%; }

.container-notice-privacy {
  background-image: url("/img/022_backlines.svg");
  background-repeat: repeat-y;
  background-position: -70% 0%; }

.container-noticePrivacy {
  margin: 4em 0; }
  .container-noticePrivacy .title {
    font-weight: 600;
    color: #03619c;
    font-size: 2em;
    text-align: center; }
  .container-noticePrivacy .subtitle {
    color: #03619c;
    font-size: 0.9em;
    margin-bottom: 1em; }
  .container-noticePrivacy .normal {
    font-size: 0.9rem;
    color: #404854; }
  .container-noticePrivacy .section {
    margin-bottom: 2em; }
  .container-noticePrivacy .flex-center {
    display: flex;
    justify-content: center;
    margin-top: 4em; }
    .container-noticePrivacy .flex-center .container-footer-info {
      font-size: .8em; }
  .container-noticePrivacy a {
    color: inherit;
    font-size: inherit;
    text-decoration: inherit;
    font-size: 0.9rem; }
  .container-noticePrivacy li {
    margin-bottom: .6em;
    font-size: 0.9rem; }

.section-header-world-class .section-background {
  height: 80vh;
  min-height: 780px;
  width: 100vw;
  background-position: 0% 0%;
  background-size: cover;
  display: flex; }
  .section-header-world-class .section-background .section-text-left {
    font-weight: bold;
    color: #1e3c6f;
    font-size: 3.7em;
    line-height: 1.2;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .section-header-world-class .section-background .container-text {
    margin-top: 9em; }

.section-header-world-class .text-normal {
  font-size: 0.6em;
  font-weight: 100;
  position: relative;
  top: -.7em;
  left: -.5em; }

@media (max-width: 1280px) and (orientation: portrait) {
  .section-header-world-class .section-background {
    align-items: normal;
    height: 50vh !important;
    min-height: 740px;
    background-position: 30% 0%; }
    .section-header-world-class .section-background .section-text-left {
      margin-top: 1em; } }

@media (max-width: 1280px) and (orientation: landscape) {
  .section-header-world-class .section-background {
    align-items: normal;
    height: 50vh !important;
    background-position: 20% 0%;
    min-height: 630px; }
    .section-header-world-class .section-background .section-text-left {
      margin-top: 1em; } }

@media (max-width: 600px) and (orientation: portrait) {
  .section-header-world-class .section-text-left {
    font-size: 3.7em !important; }
  .section-header-world-class .section-background {
    align-items: normal;
    height: 60vh !important;
    background-position: 50% 0%;
    min-height: 550px; }
    .section-header-world-class .section-background .section-text-left {
      margin-top: 1em; } }

@media (max-width: 600px) and (orientation: landscape) {
  .section-header-world-class .section-text-left {
    font-size: 3.7em !important; }
  .section-header-world-class .section-background {
    align-items: normal;
    height: 60vh !important;
    background-position: 50% 0%; }
    .section-header-world-class .section-background .section-text-left {
      margin-top: 1em; } }

.section-que-es-contabilidad {
  font-size: 16px !important; }
  @media (max-width: 600px) {
    .section-que-es-contabilidad {
      font-size: 14px !important; } }
  .section-que-es-contabilidad .title {
    padding: 3.5em 1em;
    background-color: white;
    font-size: 1.22em; }
    .section-que-es-contabilidad .title .text {
      color: #404854; }
  .section-que-es-contabilidad .container-info {
    padding: 0 0 4.5rem 0; }
  .section-que-es-contabilidad .image-general-info {
    padding: 0 2em;
    text-align: center; }
    .section-que-es-contabilidad .image-general-info img {
      max-width: 400px; }
  .section-que-es-contabilidad .general-info {
    padding: 1.5rem 0 0 0;
    display: grid;
    align-items: center;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr)); }
  .section-que-es-contabilidad .container-line {
    position: relative;
    font-size: 1.22em;
    padding: 0 0 0 2em; }
    .section-que-es-contabilidad .container-line .line-left {
      position: absolute;
      height: 11em;
      width: .4em;
      background-color: #139CE1;
      left: 0; }
  .section-que-es-contabilidad .container-important {
    background-color: #139CE1;
    color: white !important;
    padding: 1.5em 1.5em;
    margin: 0 .5em; }
  .section-que-es-contabilidad .font-italic {
    font-style: italic; }

.section-product-class-world {
  font-size: 16px !important; }
  @media (max-width: 600px) {
    .section-product-class-world {
      font-size: 14px !important; } }
  .section-product-class-world .text {
    color: #404854;
    font-size: 1rem; }
  .section-product-class-world .container-title {
    background-color: #139CE1;
    padding: 3.3em 0em; }
    .section-product-class-world .container-title .title {
      font-size: 2em; }
  .section-product-class-world .container-footer-text {
    background-color: #49C24A;
    padding: 3.3em 0em; }
  .section-product-class-world .container-words {
    display: grid;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
    margin: 3em 0; }
    @media (max-width: 600px) {
      .section-product-class-world .container-words {
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr)); } }
    .section-product-class-world .container-words .first-letter {
      font-size: 5em;
      font-weight: 600;
      color: #139CE1; }
    .section-product-class-world .container-words .word {
      color: #404854; }
  .section-product-class-world .colum-levels {
    display: grid;
    grid-column-gap: .5em;
    grid-row-gap: .5em;
    grid-template-columns: 35% 65%;
    margin: 3em 0; }
  .section-product-class-world .image-general-info {
    padding: 0 2em;
    text-align: center; }
    .section-product-class-world .image-general-info img {
      max-width: 400px; }
  .section-product-class-world .general-info {
    padding: 1.5rem 0 0 0;
    display: grid;
    grid-column-gap: 2em;
    grid-row-gap: 0em;
    font-size: 1.22em;
    grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr)); }
  .section-product-class-world .container-line {
    position: relative;
    padding: 0 0 0 2em; }
    .section-product-class-world .container-line .line-left {
      position: absolute;
      height: 11em;
      width: .4em;
      background-color: #139CE1;
      left: 0; }
    .section-product-class-world .container-line .container-levels {
      padding: 2em 0; }
  .section-product-class-world .text-right {
    text-align: right; }

.section-header-nosotros .icon-header {
  width: 100%;
  max-width: 150px; }

.section-header-nosotros .section-background {
  height: 80vh;
  min-height: 780px;
  width: 100vw;
  background-position: 50% 50%;
  background-size: cover;
  display: flex; }
  .section-header-nosotros .section-background .section-text-left {
    font-weight: bold;
    color: #1e3c6f;
    font-size: 3.7em;
    line-height: 1.2;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .section-header-nosotros .section-background .section-text-left h1 {
      margin-top: 0 !important; }
  .section-header-nosotros .section-background .container-text {
    margin-top: 4em; }

.section-header-nosotros .text-normal {
  font-size: 0.6em;
  font-weight: 100;
  position: relative;
  top: -.7em;
  left: -.5em; }

@media (max-width: 1280px) and (orientation: portrait) {
  .section-header-nosotros .section-background {
    align-items: normal;
    height: 50vh !important;
    min-height: 740px;
    background-position: 50% 50%; }
    .section-header-nosotros .section-background .section-text-left {
      margin-top: 2em; } }

@media (max-width: 1280px) and (orientation: landscape) {
  .section-header-nosotros .section-background {
    align-items: normal;
    height: 50vh !important;
    background-position: 50% 50%;
    min-height: 630px; }
    .section-header-nosotros .section-background .section-text-left {
      margin-top: 2em; } }

@media (max-width: 600px) and (orientation: portrait) {
  .section-header-nosotros .section-text-left {
    font-size: 3.7em !important; }
  .section-header-nosotros .section-background {
    align-items: normal;
    height: 60vh !important;
    background-position: 50% 50%;
    min-height: 550px; }
    .section-header-nosotros .section-background .section-text-left {
      margin-top: 3em; }
  .section-header-nosotros .icon-header {
    width: 100%;
    max-width: 80px; } }

@media (max-width: 600px) and (orientation: landscape) {
  .section-header-nosotros .section-text-left {
    font-size: 3.7em !important; }
  .section-header-nosotros .section-background {
    align-items: normal;
    height: 60vh !important;
    background-position: 50% 50%; }
    .section-header-nosotros .section-background .section-text-left {
      margin-top: 3em; }
  .section-header-nosotros .icon-header {
    width: 100%;
    max-width: 80px; } }

.section-que-es-contabilidad-nosotros {
  font-size: 16px !important; }
  @media (max-width: 600px) {
    .section-que-es-contabilidad-nosotros {
      font-size: 14px !important; } }
  .section-que-es-contabilidad-nosotros .title {
    padding: 3.5em 1em;
    background-color: white;
    font-size: 1.22em; }
    .section-que-es-contabilidad-nosotros .title .text {
      color: #404854; }
  .section-que-es-contabilidad-nosotros .container-info {
    padding: 0 0 4.5rem 0; }
  .section-que-es-contabilidad-nosotros .image-general-info {
    padding: 0 2em;
    text-align: center; }
    .section-que-es-contabilidad-nosotros .image-general-info img {
      max-width: 210px; }
  .section-que-es-contabilidad-nosotros .general-info {
    padding: 1.5rem 0 0 0;
    display: grid;
    align-items: center;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr)); }
  .section-que-es-contabilidad-nosotros .container-line {
    font-size: 1.22em;
    position: relative;
    padding: 0 0 0 2em; }
    .section-que-es-contabilidad-nosotros .container-line .line-left {
      position: absolute;
      height: 11em;
      width: .4em;
      background-color: #139CE1;
      left: 0; }
  .section-que-es-contabilidad-nosotros .container-important {
    background-color: #139CE1;
    color: white !important;
    padding: 1.5em 1.5em;
    margin: 0 .5em; }
  .section-que-es-contabilidad-nosotros .font-italic {
    font-style: italic; }
  .section-que-es-contabilidad-nosotros .contacrece-logo {
    max-width: 250px; }

.section-product-nosotros {
  font-size: 16px !important; }
  @media (max-width: 600px) {
    .section-product-nosotros {
      font-size: 14px !important; } }
  .section-product-nosotros .subtitle {
    font-size: 2.5em; }
  .section-product-nosotros .title-nuestro-proposito {
    font-size: 2.5em; }
  .section-product-nosotros .text-nuestro-proposito {
    font-size: 1.5em; }
  .section-product-nosotros .title-nuestro-vision {
    font-size: 2.5em; }
  .section-product-nosotros .text-nuestro-vision {
    font-size: 1.5em; }
  .section-product-nosotros .text {
    color: #404854;
    font-size: 1em; }
  .section-product-nosotros .container-title {
    background-color: #139CE1;
    padding: 3.3em 0em; }
    .section-product-nosotros .container-title p {
      font-size: 1.22em; }
  .section-product-nosotros .container-title-1 {
    background-color: #2884b0;
    padding: 3.3em 0em; }
    .section-product-nosotros .container-title-1 p {
      font-size: 1.22em; }
  .section-product-nosotros .container-title-3 {
    background-color: #03619c;
    padding: 3.3em 0em; }
    .section-product-nosotros .container-title-3 p {
      font-size: 1.22em; }
  .section-product-nosotros .container-footer-text {
    background-color: #49C24A;
    padding: 3.3em 0em; }
  .section-product-nosotros .container-title-person {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    padding: 13em 0; }
    @media (max-width: 600px) {
      .section-product-nosotros .container-title-person {
        padding: 4em 0; } }
  .section-product-nosotros .container-title-flag {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    padding: 13em 0; }
    @media (max-width: 600px) {
      .section-product-nosotros .container-title-flag {
        padding: 4em 0; } }
  .section-product-nosotros .container-words {
    display: grid;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
    margin: 3em 0; }
    @media (max-width: 600px) {
      .section-product-nosotros .container-words {
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr)); } }
    .section-product-nosotros .container-words .first-letter {
      font-size: 5em;
      font-weight: 600;
      color: #139CE1; }
    .section-product-nosotros .container-words .word {
      color: #404854; }
  .section-product-nosotros .colum-levels {
    display: grid;
    grid-column-gap: .5em;
    grid-row-gap: .5em;
    grid-template-columns: 35% 65%;
    margin: 3em 0; }
  .section-product-nosotros .image-general-info {
    padding: 0 2em;
    text-align: center; }
    .section-product-nosotros .image-general-info img {
      max-width: 400px; }
  .section-product-nosotros .general-info {
    padding: 1.5rem 0 0 0;
    display: grid;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));
    align-items: center; }
  .section-product-nosotros .container-line {
    position: relative;
    padding: 0 0 0 2em; }
    .section-product-nosotros .container-line .line-left {
      position: absolute;
      height: 11em;
      width: .4em;
      background-color: #139CE1;
      left: 0; }
    .section-product-nosotros .container-line .container-levels .title {
      font-size: 2em; }
    .section-product-nosotros .container-line .container-levels p {
      font-size: 1.2em; }
  .section-product-nosotros .text-right {
    text-align: right; }
  .section-product-nosotros .container-nuestra-vision {
    margin: 4em 0; }
  .section-product-nosotros .img-valores {
    max-width: 480px; }

.section-header-progressive-accounting .section-background {
  height: 80vh;
  min-height: 780px;
  width: 100vw;
  background-position: 0% 0%;
  background-size: cover;
  display: flex; }
  .section-header-progressive-accounting .section-background .section-text-left {
    font-weight: bold;
    color: #1e3c6f;
    font-size: 3.7em;
    line-height: 1.2;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .section-header-progressive-accounting .section-background .container-text {
    margin-top: 9em; }

.section-header-progressive-accounting .text-normal {
  font-size: 0.6em;
  font-weight: 100;
  position: relative;
  top: -.7em;
  left: -.5em; }

@media (max-width: 1280px) and (orientation: portrait) {
  .section-header-progressive-accounting .section-background {
    align-items: normal;
    height: 50vh !important;
    min-height: 740px;
    background-position: 30% 0%; }
    .section-header-progressive-accounting .section-background .section-text-left {
      margin-top: 1em; } }

@media (max-width: 1280px) and (orientation: landscape) {
  .section-header-progressive-accounting .section-background {
    align-items: normal;
    height: 50vh !important;
    background-position: 20% 0%;
    min-height: 630px; }
    .section-header-progressive-accounting .section-background .section-text-left {
      margin-top: 1em; } }

@media (max-width: 600px) and (orientation: portrait) {
  .section-header-progressive-accounting .section-text-left {
    font-size: 3.7em !important; }
  .section-header-progressive-accounting .section-background {
    align-items: normal;
    height: 60vh !important;
    background-position: 50% 0%;
    min-height: 550px; }
    .section-header-progressive-accounting .section-background .section-text-left {
      margin-top: 1em; } }

@media (max-width: 600px) and (orientation: landscape) {
  .section-header-progressive-accounting .section-text-left {
    font-size: 3.7em !important; }
  .section-header-progressive-accounting .section-background {
    align-items: normal;
    height: 60vh !important;
    background-position: 50% 0%; }
    .section-header-progressive-accounting .section-background .section-text-left {
      margin-top: 1em; } }

.section-WhatTypicalMorning-progressive {
  font-size: 16px !important; }
  @media (max-width: 600px) {
    .section-WhatTypicalMorning-progressive {
      font-size: 14px !important; } }
  .section-WhatTypicalMorning-progressive .title {
    padding: 3.5em 1em;
    background-color: #03619c; }
    .section-WhatTypicalMorning-progressive .title .text {
      color: white;
      font-size: 1.9rem; }
  .section-WhatTypicalMorning-progressive .container-info {
    padding: 4.5rem 0;
    font-size: 1.22em; }
  .section-WhatTypicalMorning-progressive .image-general-info {
    padding: 0 2em; }
    .section-WhatTypicalMorning-progressive .image-general-info img {
      max-width: 100%; }
  .section-WhatTypicalMorning-progressive .general-info {
    padding: 4.5rem 0 0 0;
    display: grid;
    align-items: center;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr)); }
  .section-WhatTypicalMorning-progressive .container-line {
    position: relative;
    padding: 0 0 0 2em; }
    .section-WhatTypicalMorning-progressive .container-line .line-left {
      position: absolute;
      height: 9em;
      width: .4em;
      background-color: #03619c;
      left: 0; }
  .section-WhatTypicalMorning-progressive .container-tipos {
    margin-top: 2em;
    display: grid;
    grid-template-columns: 20% 80%;
    grid-column-gap: 0em;
    grid-row-gap: 2em;
    align-items: center; }
  .section-WhatTypicalMorning-progressive .images-tipos {
    max-width: 50px; }

.section-product {
  font-size: 16px !important; }
  @media (max-width: 600px) {
    .section-product {
      font-size: 14px !important; } }
  .section-product .text {
    color: #404854;
    font-size: 1rem; }
  .section-product .container-title {
    background-color: #03619c;
    padding: 3.3em 0em;
    font-size: 1.22em; }
  .section-product .container-footer-text {
    background-color: #49C24A;
    padding: 3.3em 0em;
    font-size: 1.22em; }
  .section-product .container-words {
    display: grid;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
    margin: 3em 0; }
    @media (max-width: 600px) {
      .section-product .container-words {
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr)); } }
    .section-product .container-words .first-letter {
      font-size: 5em;
      font-weight: 600;
      color: #03619c; }
    .section-product .container-words .word {
      color: #404854;
      font-size: 1.22em; }
  .section-product .container-title-product {
    padding-top: 3em;
    font-size: 1.22em; }
  .section-product .image-progressive {
    max-width: 450px; }
  .section-product .colum-levels {
    display: grid;
    grid-column-gap: .5em;
    grid-row-gap: .5em;
    grid-template-columns: 35% 65%;
    margin: 3em 0; }
  .section-product .image-general-info {
    padding: 0 2em;
    text-align: center; }
    .section-product .image-general-info img {
      max-width: 400px; }
  .section-product .general-info {
    padding: 1.5rem 0 0 0;
    display: grid;
    align-items: center;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr)); }
  .section-product .container-line {
    position: relative;
    padding: 0 0 0 2em; }
    .section-product .container-line .line-left {
      position: absolute;
      height: 11em;
      width: .4em;
      background-color: #139CE1;
      left: 0; }
    .section-product .container-line .container-levels {
      padding: 2em 0; }
  .section-product .text-right {
    text-align: right; }

.section-beneficios {
  font-size: 16px !important;
  padding: 4em 0; }
  @media (max-width: 600px) {
    .section-beneficios {
      font-size: 14px !important; } }
  .section-beneficios .text {
    color: #404854;
    font-size: 1rem; }
  .section-beneficios .container-title {
    background-color: #03619c;
    padding: 3.3em 0em; }
  .section-beneficios .container-footer-text {
    background-color: #49C24A;
    padding: 3.3em 0em; }
  .section-beneficios .container-words {
    display: grid;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
    margin: 3em 0; }
    @media (max-width: 600px) {
      .section-beneficios .container-words {
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr)); } }
    .section-beneficios .container-words .first-letter {
      font-size: 5em;
      font-weight: 600;
      color: #03619c; }
    .section-beneficios .container-words .word {
      color: #404854; }
  .section-beneficios .container-title-product {
    padding-top: 3em; }
  .section-beneficios .colum-levels {
    display: grid;
    grid-column-gap: .5em;
    grid-row-gap: .5em;
    grid-template-columns: 35% 65%;
    margin: 3em 0; }
  .section-beneficios .image-general-info {
    padding: 0 2em;
    text-align: center; }
    .section-beneficios .image-general-info img {
      max-width: 400px; }
  .section-beneficios .general-info {
    padding: 1.5rem 0 0 0;
    display: grid;
    align-items: center;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr)); }
  .section-beneficios .container-line {
    position: relative;
    padding: 0 0 0 2em;
    font-size: 1.22em; }
    .section-beneficios .container-line .line-left {
      position: absolute;
      height: 11em;
      width: .4em;
      background-color: #03619c;
      left: 0; }
    .section-beneficios .container-line .container-levels {
      padding: 2em 0; }
  .section-beneficios .text-right {
    text-align: right; }
  .section-beneficios .image-beneficios {
    max-width: 450px; }
  .section-beneficios .title {
    color: #03619c;
    font-size: 2.5em;
    font-weight: 500; }


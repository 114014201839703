
.progressCircle {
  display: inline-block;
  @keyframes load {
    0% {
      stroke-dashoffset: 0;
    }
  }
  .progress {
    position: relative;
    display: inline-block;
    padding: 0;
    text-align: center;
  }
  .porcent-text {
    font-size: 2.5em;
    font-weight: 700;
  }
  .progress .bar {
    display: inline-block;
    position: relative;
    text-align: center;
    color: #93a2ac;
    font-family: Lato;
    font-weight: 100;
    margin: 2rem;

    .container-info {
      font-family: 'Montserrat', sans-serif;
      -webkit-font-smoothing: 'Montserrat', sans-serif;
      -moz-osx-font-smoothing: 'Montserrat', sans-serif;
      position: absolute;
      top: 3em;
      text-align: center;
      width: 100%;
    }
  }
  .progress svg {
    width: 14em;
    height: 14em;
  }
  .progress svg:nth-child(2) {
    position: absolute;
    left: 0;
    top: 0;
    transform: rotate(-90deg);
  }
  .progress svg:nth-child(2) path {
    fill: none;
    stroke-width: 25;
    stroke-dasharray: 629;
    stroke: #fff;
    opacity: 0.9;
  }
  &.actived .progress svg:nth-child(2) path {
    animation: load 2s;
  }
}
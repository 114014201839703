@import './vars.scss';

//  ██████╗ ██████╗ ███╗   ██╗███████╗██╗ ██████╗ 
// ██╔════╝██╔═══██╗████╗  ██║██╔════╝██║██╔════╝ 
// ██║     ██║   ██║██╔██╗ ██║█████╗  ██║██║  ███╗
// ██║     ██║   ██║██║╚██╗██║██╔══╝  ██║██║   ██║
// ╚██████╗╚██████╔╝██║ ╚████║██║     ██║╚██████╔╝
//  ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝     ╚═╝ ╚═════╝ 
                                               
span {
  font-size: inherit;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

//  ██████╗ ██████╗ ██╗      ██████╗ ██████╗ ███████╗     ██████╗██╗      █████╗ ███████╗███████╗
// ██╔════╝██╔═══██╗██║     ██╔═══██╗██╔══██╗██╔════╝    ██╔════╝██║     ██╔══██╗██╔════╝██╔════╝
// ██║     ██║   ██║██║     ██║   ██║██████╔╝███████╗    ██║     ██║     ███████║███████╗███████╗
// ██║     ██║   ██║██║     ██║   ██║██╔══██╗╚════██║    ██║     ██║     ██╔══██║╚════██║╚════██║
// ╚██████╗╚██████╔╝███████╗╚██████╔╝██║  ██║███████║    ╚██████╗███████╗██║  ██║███████║███████║
//  ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝     ╚═════╝╚══════╝╚═╝  ╚═╝╚══════╝╚══════╝
                                                                                              
.color-text-dark {
  color: $text-color !important;
}

.color-text-dark-semi {
  color: $text-color-semi !important;
}

.color-text-dark-important {
  color: $text-color-important !important;
}

// ████████╗███████╗██╗  ██╗████████╗███████╗
// ╚══██╔══╝██╔════╝╚██╗██╔╝╚══██╔══╝██╔════╝
//    ██║   █████╗   ╚███╔╝    ██║   ███████╗
//    ██║   ██╔══╝   ██╔██╗    ██║   ╚════██║
//    ██║   ███████╗██╔╝ ██╗   ██║   ███████║
//    ╚═╝   ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝
                                          
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.light {
  font-weight: 200;
}

.regular {
  font-weight: 400;
}

.semi-bold-regular {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.font-italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.w-100 {
  width: 100%;
}
@import './../../../../vars.scss';

.section-beneficios {
  font-size: 16px !important;
  padding: 4em 0;
  @media (max-width: $sm) {
    & {
      font-size: 14px !important;
    }
  }
  .text {
    color: $text-color;
    font-size: 1rem;
  }
  .container-title {
    background-color: $text-color-semi;
    padding: 3.3em 0em;
  }

  .container-footer-text {
    background-color: $background-green;
    padding: 3.3em 0em;
  }

  .container-words {
    display: grid;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
    margin: 3em 0;

    @media (max-width: $sm) {
      & {
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
      }
    }

    .first-letter {
      font-size: 5em;
      font-weight: 600;
      color: $text-color-semi;
    }
    .word {
      color: $text-color;
    }

  }

  .container-title-product {
    padding-top: 3em;
  }

  .colum-levels {
    display: grid;
    grid-column-gap: .5em;
    grid-row-gap: .5em;
    grid-template-columns: 35% 65%;
    margin: 3em 0;
  }

  .image-general-info {
    padding: 0 2em;
    text-align: center;
    img {
      max-width: 400px;
    }
  }

  .general-info {
    padding: 1.5rem 0 0 0;
    display: grid;
    align-items: center;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));
  }

  .container-line {
    position: relative;
    padding: 0 0 0 2em;
    font-size: 1.22em;
    .line-left {
      position: absolute;
      height: 11em;
      width: .4em;
      background-color: $text-color-semi;
      left: 0;
    }

    .container-levels {
      padding: 2em 0;
    }
  }

  .text-right {
    text-align: right;
  }

  .image-beneficios {
    max-width: 450px;
  }

  .title {
    color: $text-color-semi;
    font-size: 2.5em;
    font-weight: 500;
  }
}
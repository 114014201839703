@import './../../../../vars.scss';

.section-Persons {
  font-size: 16px !important;
  @media (max-width: $sm) {
    & {
      font-size: 14px !important;
    }
  }
  .background-section {
    background-position: 50% 50%;
    background-size: cover;

    .general-info {
      padding: 4.5rem 0 4.5rem 0;
      font-size: 1.22em;
      display: grid;
      grid-column-gap: 2em;
      grid-row-gap: 2em;
      grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));
      align-items: center;
    }
  }

  .img-persons {
    max-width: 420px;
  }
}
@import './../../../../vars.scss';

.section-header-progressive-accounting {
  .section-background {
    height: 80vh;
    min-height: 780px;
    width: 100vw;
    background-position: 0% 0%;
    background-size: cover;
    display: flex;
  
    .section-text-left {
      font-weight: bold;
      color: $text-color-important;
      font-size: 3.7em;
      line-height: 1.2;
      -webkit-touch-callout: none;
        -webkit-user-select: none;
         -khtml-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
  
    .container-text {
      margin-top: 9em;
    }
  }

  .text-normal {
    font-size: 0.6em;
    font-weight: 100;
    position: relative;
    top: -.7em;
    left: -.5em;
  }
  
  @media (max-width: $lg) and (orientation: portrait) {
    .section-background {
      align-items: normal;
      height: 50vh !important;
      min-height: 740px;
      background-position: 30% 0%;
      .section-text-left {
        margin-top: 1em;
      }
    }
  }
  
  @media (max-width: $lg) and (orientation: landscape) {
    .section-background {
      align-items: normal;
      height: 50vh !important;
      background-position: 20% 0%;
      min-height: 630px;
      .section-text-left {
        margin-top: 1em;
      }
    }
  }
  
  @media (max-width: $sm) and (orientation: portrait)  {
    .section-text-left {
      font-size: 3.7em !important;
    }
    .section-background {
      align-items: normal;
      height: 60vh !important;
      background-position: 50% 0%;
      min-height: 550px;
      .section-text-left {
        margin-top: 1em;
      }
    }
  }
  
  @media (max-width: $sm) and (orientation: landscape)  {
    .section-text-left {
      font-size: 3.7em !important;
    }
    .section-background {
      align-items: normal;
      height: 60vh !important;
      background-position: 50% 0%;
      .section-text-left {
        margin-top: 1em;
      }
    }
  }
}
@import './../../../../vars.scss';

.formToChangeData {
  .container-form {
    form {
      padding: 2em;
    }
  }
  .containerInput {
    margin-bottom: 2em;
  }
  .inputs {
    width: 100%;
  }
}
@import './../../../vars.scss';

.section-header-stories {
  position: relative;
  .section-background {
    height: 90vh;
    min-height: 930px;
    width: 100vw;
    background-position: 0% 100%;
    background-size: cover;
    display: flex;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow:  none;
    margin-bottom: -11em;

    .container-text {
      margin: 0;
      position: absolute;
      top: 175px;
      width: 100%;
      text-align: center;
    }
  
    .section-text-center {
      font-weight: bold;
      text-transform: uppercase;
      color: #03619c;
      font-size: 5.2em;
      line-height: 1.2;
      -webkit-touch-callout: none;
        -webkit-user-select: none;
         -khtml-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;

      h1 {
        margin-bottom: 2rem;
      }

      h2 {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 500;
        color: #03619c;
      }
    }
  } 
}

@media (max-width: $lg) and (orientation: portrait) {
  .section-background {
    min-height: 720px !important;
    .section-text-center {
      font-size: 3.8em !important;
    }

    .container-text {
      top: 175px;
    }
  }
}

@media (max-width: $lg) and (orientation: landscape) {
  .section-background {
    min-height: 720px !important;
    .section-text-center {
      font-size: 3.8em !important;
    }

    .container-text {
      top: 175px;
    }
  }
}

@media (max-width: $sm) and (orientation: portrait)  {
  .section-background {
    min-height: 400px !important;

    .section-text-center {
      font-size: 3em !important;
    }

    .container-text {
      top: 125px !important;
    }
  }
}

@media (max-width: $sm) and (orientation: landscape)  {
  .section-background {
    min-height: 400px !important;

    .section-text-center {
      font-size: 3em !important;
    }

    .container-text {
      top: 125px !important;
    }
  }
}
@import './../../../../vars.scss';

.section-AdministrativeAssistant {
  font-size: 16px !important;
  background-color: $background-blue-light;
  padding-top: 2em;
  padding-bottom: 6em;
  @media (max-width: $sm) {
    & {
      font-size: 14px !important;
    }
  }
  .title {
    padding: 3.5em 1em;
    background-color: $background-blue-light;
    .text {
      color: white;
      font-size: 2rem;
      font-weight: 500;
    }
  }

  .container-administrativeAssistant {
    margin-bottom: 3em;
    img {
      max-width: 200px;
      width: 100%;
    }
  }

  .info-text {
    font-size: 1.22em;
  }
}
@import './../../../vars.scss'; 

.section-post {

  background-color: rgba(0, 0, 0, 0);
  position: relative;
  width: 100%;
  padding: 0 4em;
  margin-bottom: 5em;

  @media (max-width: $lg) and (orientation: portrait) {
    & {
      padding: 0 5em !important;
    }
  }
  
  @media (max-width: $lg) and (orientation: landscape) {
    & {
      padding: 0 5em !important;
    }
  }
  
  @media (max-width: $sm) and (orientation: portrait)  {
    & {
      padding: 0 1em !important;
    }
  }
  
  @media (max-width: $sm) and (orientation: landscape)  {
    & {
      padding: 0 1em !important;
    }
  }

  .posts {

    margin: 3em auto 9em auto;
    max-width: 1200px;
  
    .title {
      color: #1e3c6f;
      font-size: 3.5em;
      line-height: 1.2;
      font-weight: 200;
      text-transform: uppercase;
    }
  
    .createdBy {
      color: #a4a4a5;
      font-size: 1.4em;
      font-weight: 300;
    }
  
    .text {
      font-weight: 300;
      color: #404854;
      p {
        font-size: 1.1em;
      }
    }
  
    .btn-full-note {
      background-color: #42d1ab;
      color: white;
      padding: .7em 2.2em;
      font-weight: 300;
      text-transform: uppercase;
    }
  
    .btn-full-note:hover {
      background-color: #21aa83;
    }
  
    a {
      text-decoration: none;
    }
    
    .container-img {
      text-align: center;
    }

    img {
      max-width: 100%;
    }

    h1 {
      font-size: 2em;
      font-weight: 600;
    }

    h2 {
      font-size: 1.7em;
      font-weight: 500;
    }

    h3 {
      font-size: 1.4em;
      font-weight: 500;
    }

    h4 {
      font-size: 1.2em;
      font-weight: 400;
    }

    .content-socialnetwork {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .title-follow {
        color: rgb(3, 97, 156);
        font-size: 1.2em;
        margin-right: .5em;
      }

      .icon-socialNetwork {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
  
      .icon-socialNetwork img {
        width: 1.8em;
        margin-right: .5em;
      }

      a {
        height: 36px;
      }
    }
  }
}

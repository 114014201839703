@import './../../../vars.scss';

.section-between {
  // background-repeat: no-repeat;
  // background-size: 130%;
  // background-position: 20% 22%;

  background-attachment: fixed;
  background-position: 20% 22%;
  background-repeat: no-repeat;
  background-size:  130%;

  padding-top: 10em;
  margin-bottom: -13em;
  padding-bottom: 20em;
}

.section-help {
  color: white;

  .title {
    font-size: 2em;
    margin-bottom: 3em;
    margin-bottom: 1.4em;
  }

  .text {
    font-size: 1.6em;
  }
}

@media (max-width: $lg) and (orientation: portrait) {
  .section-between {
    padding-top: 12em;
    margin-bottom: -6em;
    padding-bottom: 12em;
    background-size: cover;
  }
}

@media (max-width: $lg) and (orientation: landscape) {
  .ssection-between {
    padding-top: 12em;
    margin-bottom: -6em;
    padding-bottom: 12em;
    background-size: cover;
  }
}

@media (max-width: $md) and (orientation: portrait) {
  .section-between {
    padding-top: 5em;
    margin-bottom: -2em;
    padding-bottom: 5em;
    background-size: cover;
  }
}

@media (max-width: $md) and (orientation: landscape) {
  .ssection-between {
    padding-top: 5em;
    margin-bottom: -2em;
    padding-bottom: 5em;
    background-size: cover;
  }
}

@media (max-width: $sm) and (orientation: portrait) {
  .section-between {
    padding-top: 5em;
    margin-bottom: -2em;
    padding-bottom: 5em;
    background-size: cover;
  }
}

@media (max-width: $sm) and (orientation: landscape) {
  .ssection-between {
    padding-top: 5em;
    margin-bottom: -2em;
    padding-bottom: 5em;
    background-size: cover;
  }
}
@import './../../../vars.scss';

.container-contact {

  .MuiButtonBase-root {
    color: rgba(0, 170, 209, 1);
    border: 1px solid rgba(0, 170, 209, 0.23);
  }

  .container-form {
    background-color: white;
    max-width: 1048px;
    width: calc(100% - 4em);
    margin: auto;
    border-radius: 20px 20px 0 0;
    padding: 2em;
    // -webkit-box-shadow: -14px -14px 19px -9px rgba(70,70,70,.3), 14px -14px 19px -9px rgba(70,70,70,.3); 
    // box-shadow: -14px -14px 19px -9px rgba(70,70,70,.3), 14px -14px 19px -9px rgba(70,70,70,.3);
    -webkit-box-shadow: -14px -14px 19px -9px rgba(0, 74, 119,0.2), 14px -14px 19px -9px rgba(0, 74, 119,0.30); 
    box-shadow: -14px -14px 19px -9px rgba(0, 74, 119,0.2), 14px -14px 19px -9px rgba(0, 74, 119,0.30);
  }

  .container-contact-info {
    display: grid;
    grid-template-columns: calc(50% - 3em) 50%;
    grid-column-gap: 3em;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid $color-form;
  }

  .MuiFormLabel-root.Mui-focused {
    color: $color-form;
  }

  .section-btn-map {
    display: grid;
    grid-template-columns: 30% 70%;
  }

  .btn-map {
    cursor: pointer;
    img {
      width: 4.5em;
      max-width: 80%;
    }
  }

  .text-address {
    font-weight: 300;
    color: rgb(0, 170, 209);
  }

  .map {
    position: relative !important;
    width: 100% !important;
    height: 600px !important;
  }

  .myMark {
    height: 10px;
    width: 10px;
    background-color: red;
  }

  .title-contact {
    color: $color-gray-dark;
    font-size: 1.5em;
  }

  .send-message {
    color: $text-color-semi;
    font-size: 1.5em;
  }

  .orCall {
    font-weight: 200;
    color: #404854;
  }

  .phone {
    color: $color-phone;
    text-decoration: none;
    font-size: 1.8em;
  }

  .contact-text-info {
    font-size: 1.3em;
    line-height: 1.2;
  }

  .link {
    color: $color-form;
    text-decoration: underline;
    font-size: inherit;
  }

  .title-follow {
    color: $text-color-semi;
    font-size: 1.4em;
  }

  .content-socialnetwork {
    .icon-socialNetwork {
      margin-top: 1em;
      img {
        width: 1.8em;
        margin-right: .7em;
      }
    }
  }

  .flex-space-between {
    display: flex;
    justify-content: space-between;
    border-top: solid 1px #949494;
    a {
      color: inherit;
      font-size: inherit;
      text-decoration: inherit;
    }
  }
}

.MuiSnackbar-root .MuiTypography-root {
  background-color: $text-color-important;
  color: white;

  #message-id {
    display: flex;
    align-items: center;
  }
}


.container-notice-privacy {
  background-image: url('/img/022_backlines.svg');
  background-repeat: repeat-y;
  background-position: -70% 0%;
}

.container-noticePrivacy {
  margin: 4em 0;

  .title {
    font-weight: 600;
    color: #03619c;
    font-size: 2em;
    text-align: center;
  }

  .subtitle {
    color: #03619c;
    font-size: 0.9em;
    margin-bottom: 1em;
  }

  .normal {
    font-size: 0.9rem;
    color: #404854;
  }
  .section {
    margin-bottom: 2em;
  }

  .flex-center {
    display: flex;
    justify-content: center;
    margin-top: 4em;

    .container-footer-info {
      font-size: .8em;
    }
  }

  a {
    color: inherit;
    font-size: inherit;
    text-decoration: inherit;
    font-size: 0.9rem;
  }

  li {
    margin-bottom: .6em;
    font-size: 0.9rem;
  }
}
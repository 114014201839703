@import './../../../vars.scss';

footer {
  margin-top: 1px;
  .flex-space-between {
    display: flex;
    justify-content: space-between;
    border-top: solid 1px #949494;
    a {
      color: inherit;
      font-size: inherit;
      text-decoration: inherit;
    }
  }
  .container-footer-info {
    padding: 1.5em 1em;
  }
}
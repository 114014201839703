@import './../../../vars.scss'; 

.section-back-posts {
  background-color: transparent;
  position: relative;
  width: 100%;
  padding: 0 4em;
  margin-bottom: 5em;
}

.card-posts {
  background-color: white;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  border-radius: 15px 15px 0 0;
  box-shadow: 0px 0px 20px 6px rgba(178, 222, 249, .5);
  // box-shadow: 0 24px 38px 3px rgba(178, 222, 249,0.14), 0 9px 46px 8px rgba(178, 222, 249,0.12), 0 11px 15px -7px rgba(178, 222, 249,0.2);
  padding: 1em;

  .title-historias {
    color: #404854;
    text-transform: uppercase;
    font-size: 1.2em;
  }

  .split-line {
    width: 100%;
    max-width: 170px;
    background-color: #b5b7ba;
    height: 1px;
    margin: auto;
    margin-top: 3em;
    margin-bottom: 5em;
  }
}

@media (max-width: $lg) and (orientation: portrait) {
  .section-back-posts {
    padding: 0 5em !important;
  }
}

@media (max-width: $lg) and (orientation: landscape) {
  .section-back-posts {
    padding: 0 5em !important;
  }
}

@media (max-width: $sm) and (orientation: portrait)  {
  .section-back-posts {
    padding: 0 1em !important;
  }
}

@media (max-width: $sm) and (orientation: landscape)  {
  .section-back-posts {
    padding: 0 1em !important;
  }
}

.all-note {
  .btn-all-note {
    background-color: #1e3c6f;
    color: white;
    padding: .7em 2.2em;
    font-weight: 300;
    text-transform: uppercase;
  }
  
  .btn-all-note:hover {
    background-color: #2162c1;
  }
}
@import './../../../vars.scss';

.section-statistics {
  padding: 2em 0 8em 0;

  .title-entorno-empresarial {
    font-size: 2.1em;
    border-left: solid 0.2em rgba(0, 0, 0, 0);
    padding-left: 3rem;
    color: rgb(3, 97, 156);
    font-weight: 600;
  }

  .main-text {
    font-size: 1.3em;
    font-weight: 300;
  }

  .img-futuro {
    width: 7.5em;
  }

  .container-statistics {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
  }

  .container-text-progress {
    font-size: .8em;
    font-weight: 500;
  }

  .color-yellow {
    color: $color-yellow;
  }
  .color-orange {
    color: $color-orange;
  }

  .container-text-empresas {
    max-width: 770px;
  }

  .line-right {
    border-right: solid .4em $color-line;
  }

  @media (max-width: $sm) and (orientation: portrait)  {
    .container-statistics {
      grid-template-columns: 100%;
    }
  }
  
  @media (max-width: $sm) and (orientation: landscape)  {
    .container-statistics {
      grid-template-columns: 100%;
    }
  }
}

@import './../../../vars.scss';

.section-whatDoWeDo {
  background-color: $back-services;
  padding: 10em 0;

  .MuiExpansionPanelDetails-root {
    padding-left: 0;
  }

  .title-section-whatDoWeDo {
    .title-section {
      font-size: 2.1em;
      border-left: solid 0.2em transparent;
      padding-left: 3rem;
    }
  }

  .container-more-information {
    width: 100%;
    text-align: right;
    a {
      color: $background-blue;
      text-decoration: none;
    }
  }

  .container-info-what-do-we-do {
    font-weight: 400;
    border-left: solid 0.4em #2884b0;
    padding-left: 3rem;
    margin: 1em 0;
    padding: 1em 0em 1em 3em;
  }

  .container-expands {
    max-width: 800px;
    margin: auto;

    .MuiPaper-root {
      border-radius: 10px 10px 0 0;
      box-shadow: 0 4px 5px 0 rgba(178,222,249,0.14), 0 1px 10px 0 rgba(178,222,249,0.12), 0 2px 4px -1px rgba(178,222,249,0.3);
      .MuiExpansionPanelSummary-root {
        padding-left: 0;
      }
      .MuiExpansionPanelSummary-content {
        margin: 0;
      }
    }

    .content-expand {
      margin-bottom: 1em;
    }
    .title-expand {
      width: 100%;
      margin: 1em;
      display: grid;
      align-items: center;
      justify-items: left;
      grid-template-columns: 20% 80%;

      .container-img {
        width: 5em;
        width: 100%;
        img {
          display: block;
          margin: 0 auto;
          height: 3.5em;
        }
      }
      .container-title-expand {
        padding: 1.3em 0px;
        .title {
          color: #1e3c6f;
          font-weight: 500;
        }
        .info {
          margin-top: .5em;
          font-size: .9em;
          color: #6b6b6b;
          font-weight: 400;
        }
      }
    }
  }

  .container-section-info-expand {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 40% 60%;

    .container-icon {
      width: 70%;
      .icon-logo {
        width: 100%;
      }
    }

    .container-info {
      margin-top: .5em;
      font-size: .9em;
      color: #6b6b6b;
      font-weight: 300;
      
      ol {
        padding-left: 20px;
        li {
          margin-top: 1em;
        }
      }
    }
  }

  .subtitle-section {
    font-size: 1.3em;
  }
}
@import './../../../vars.scss';

.section-accompaniment {
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: 50% 50%;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  padding-top: 10em;
  padding-bottom: 10em;

  color: white;

  .img-people {
    width: 6em;
  }

  .title {
    font-size: 2em;
    margin-bottom: 3em;
    margin-bottom: 1.4em;
  }

  .text {
    font-size: 1em;
  }
}
@import './../../../../vars.scss';

.section-Points {
  font-size: 16px !important;
  @media (max-width: $sm) {
    & {
      font-size: 14px !important;
    }
  }
  .container-expands {
    max-width: 800px;
    margin: auto;

    .MuiPaper-root {
      border-radius: 10px 10px 0 0;
      box-shadow: 0 4px 5px 0 rgba(178,222,249,0.14), 0 1px 10px 0 rgba(178,222,249,0.12), 0 2px 4px -1px rgba(178,222,249,0.3);
      .MuiExpansionPanelSummary-root {
        padding-left: 0;
      }
      .MuiExpansionPanelSummary-content {
        margin: 0;
      }
    }

    .content-expand {
      margin-bottom: 1em;
    }
    .title-expand {
      width: 100%;
      margin: 1em;

      .container-img {
        width: 5em;
        width: 100%;
        img {
          display: block;
          margin: 0 auto;
          height: 3.5em;
        }
      }
      .container-title-expand {
        padding: 1.3em 0px;
        .title {
          color: #1e3c6f;
          font-weight: 500;

          span {
            transition: color 250ms;
            &.active {
              color: $color-blue-light;
            }
          }
        }
        .info {
          margin-top: .5em;
          font-size: .9em;
          color: #6b6b6b;
          font-weight: 400;
        }
      }
    }
  }
  .container-office-svg {
    margin-bottom: 4em;
  }
}
@import './../../../../vars.scss';

.section-WhatTypicalMorning-progressive {
  font-size: 16px !important;
  @media (max-width: $sm) {
    & {
      font-size: 14px !important;
    }
  }
  .title {
    padding: 3.5em 1em;
    background-color: $text-color-semi;
    .text {
      color: white;
      font-size: 1.9rem;
    }
  }

  .container-info {
    padding: 4.5rem 0;
    font-size: 1.22em;
  }

  .image-general-info {
    padding: 0 2em;
    img {
      max-width: 100%;
    }
  }

  .general-info {
    padding: 4.5rem 0 0 0;
    display: grid;
    align-items: center;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));
  }

  .container-line {
    position: relative;
    padding: 0 0 0 2em;
    .line-left {
      position: absolute;
      height: 9em;
      width: .4em;
      background-color: $text-color-semi;
      left: 0;
    }
  }

  .container-tipos {
    margin-top: 2em;
    display: grid;
    grid-template-columns: 20% 80%;
    grid-column-gap: 0em;
    grid-row-gap: 2em;
    align-items: center;
  }

  .images-tipos {
    max-width: 50px;
  }
}